// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-insights-js": () => import("./../../../src/pages/insights.js" /* webpackChunkName: "component---src-pages-insights-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-startups-js": () => import("./../../../src/pages/startups.js" /* webpackChunkName: "component---src-pages-startups-js" */),
  "component---src-templates-about-template-js": () => import("./../../../src/templates/aboutTemplate.js" /* webpackChunkName: "component---src-templates-about-template-js" */),
  "component---src-templates-assessment-template-js": () => import("./../../../src/templates/assessmentTemplate.js" /* webpackChunkName: "component---src-templates-assessment-template-js" */),
  "component---src-templates-book-template-js": () => import("./../../../src/templates/bookTemplate.js" /* webpackChunkName: "component---src-templates-book-template-js" */),
  "component---src-templates-home-template-js": () => import("./../../../src/templates/homeTemplate.js" /* webpackChunkName: "component---src-templates-home-template-js" */),
  "component---src-templates-landing-template-js": () => import("./../../../src/templates/landingTemplate.js" /* webpackChunkName: "component---src-templates-landing-template-js" */),
  "component---src-templates-letter-template-js": () => import("./../../../src/templates/letterTemplate.js" /* webpackChunkName: "component---src-templates-letter-template-js" */),
  "component---src-templates-location-template-js": () => import("./../../../src/templates/locationTemplate.js" /* webpackChunkName: "component---src-templates-location-template-js" */),
  "component---src-templates-media-template-js": () => import("./../../../src/templates/mediaTemplate.js" /* webpackChunkName: "component---src-templates-media-template-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/pageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-partner-template-js": () => import("./../../../src/templates/partnerTemplate.js" /* webpackChunkName: "component---src-templates-partner-template-js" */),
  "component---src-templates-post-template-js": () => import("./../../../src/templates/postTemplate.js" /* webpackChunkName: "component---src-templates-post-template-js" */),
  "component---src-templates-role-template-js": () => import("./../../../src/templates/roleTemplate.js" /* webpackChunkName: "component---src-templates-role-template-js" */),
  "component---src-templates-service-template-js": () => import("./../../../src/templates/serviceTemplate.js" /* webpackChunkName: "component---src-templates-service-template-js" */),
  "component---src-templates-team-template-js": () => import("./../../../src/templates/teamTemplate.js" /* webpackChunkName: "component---src-templates-team-template-js" */),
  "component---src-templates-thanks-template-js": () => import("./../../../src/templates/thanksTemplate.js" /* webpackChunkName: "component---src-templates-thanks-template-js" */),
  "component---src-templates-work-template-js": () => import("./../../../src/templates/workTemplate.js" /* webpackChunkName: "component---src-templates-work-template-js" */)
}

